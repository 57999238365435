import React from "react"

import Layout from "../components/layout"
import SEO from "../components/layout/seo"

import { MainVisualImg } from "../components/index/MainVisualImg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="M2MTech : Not found" />
    <div className="notFound">
      <div className="imgWrap">
        <MainVisualImg />
      </div>
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that are in construction or doesn&#39;t exist. We
        apologize for the inconvenience.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
